import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as EndRexLogo } from '@icons/Logo.svg';
const LogoContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
const Title = styled.h1(props => ({
    display: 'inline',
    margin: `0 0 0 ${props.theme.spacing_sizes.xs}px`,
    userSelect: 'none',
    fontSize: 24,
}));
const EndrexLogo = () => (_jsxs(LogoContainer, { children: [_jsx(Icon, { IconComponent: EndRexLogo, size: 40, keepOriginalColor: true }), _jsx(Title, { children: "Endrex" })] }));
export default EndrexLogo;
