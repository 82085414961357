import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as EndRexLogo } from '@icons/Logo.svg';
import { Title as TitleComponent } from '@components/styled/Typography';
const LogoContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
const Title = styled(TitleComponent)(props => ({
    display: 'inline',
    margin: `0 0 0 ${props.theme.spacing_sizes.xs}px`,
    fontSize: 24,
    pointerEvents: 'none',
    cursor: 'default',
    userSelect: 'none',
}));
const LogoWithText = () => (_jsxs(LogoContainer, { role: 'img', children: [_jsx(Icon, { IconComponent: EndRexLogo, size: 40, keepOriginalColor: true }), _jsx(Title, { children: "Endrex" })] }));
export default LogoWithText;
