import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { BodySemiBold } from '@components/styled/Typography';
const IconWithTextContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: `${props.theme.spacing_sizes.xs * 5}px`,
    maxWidth: `${props.theme.baseSize * 70}px`,
    flex: 1,
    justifyContent: 'center',
    margin: props.margin,
}));
const IconContainer = styled.div(props => ({
    width: `${props.theme.spacing_sizes.xs * 8.75}px`,
    height: `${props.theme.spacing_sizes.xs * 8.75}px`,
    borderRadius: '50%',
}));
const Text = styled(BodySemiBold, { shouldForwardProp: propName => propName !== 'textMaxWidth' && propName !== 'lineHeight' })((props) => ({
    fontWeight: 600,
    fontSize: `${props.theme.baseSize * 4}px`,
    textAlign: 'center',
    maxWidth: props.maxWidth,
    lineHeight: props.lineHeight,
}));
const IconWithText = ({ IconElement, text, margin = undefined, textMaxWidth = undefined, textLineHeight = undefined, }) => (_jsxs(IconWithTextContainer, { margin: margin, children: [_jsx(IconContainer, { children: IconElement }), _jsx(Text, { maxWidth: textMaxWidth, lineHeight: textLineHeight, children: text })] }));
export default IconWithText;
