import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import ItemsListComponent from '@mui/material/List';
import ListItemComponent from '@mui/material/ListItem';
import ListItemTextComponent from '@mui/material/ListItemText';
import ListItemIconComponent from '@mui/material/ListItemIcon';
import { ReactComponent as CheckIconComponent, } from '@icons/wolfkit-solid/checkmark-with-check-circle-solid.svg';
const ItemsListContainer = styled(ItemsListComponent)(() => ({
    paddingTop: 0,
    paddingBottom: 0,
}));
const ListItemIcon = styled(ListItemIconComponent)(props => ({
    minWidth: 'unset',
    marginRight: `${props.theme.spacing_sizes.xs * 2}px`,
}));
const ListItemText = styled(ListItemTextComponent, { shouldForwardProp: propName => propName !== 'listItemTextStyles' })(props => ({
    '&.MuiListItemText-root': Object.assign({ marginTop: 0, marginBottom: 0 }, (props.listItemTextStyles || {})),
    span: {
        color: props.theme.palette.text.primary,
        fontSize: `${props.theme.spacing_sizes.xs * 2}px`,
    },
}));
const CheckIcon = styled(CheckIconComponent, { shouldForwardProp: propName => propName !== 'isSolid' })(props => ({
    width: `${props.theme.baseSize * 3}px`,
    circle: {
        fill: props.isSolid ? props.theme.palette.primary.main : undefined,
    },
    path: {
        stroke: props.isSolid ? '#fff' : undefined,
    },
}));
const ListItem = styled(ListItemComponent, { shouldForwardProp: propName => propName !== 'padding' && propName !== 'listItemStyles' })((props) => ({
    '&.MuiListItem-root': Object.assign({ padding: props.padding ? props.padding : `${props.theme.spacing_sizes.xs}px 0px` }, (props.listItemStyles || {})),
}));
const ItemsList = ({ list, listStyle = 'check-icon', itemPadding = undefined, listItemTextStyles = {}, listItemStyles = {}, }) => (_jsx(ItemsListContainer, { children: list.map((listItem, key) => (_jsxs(ListItem, { padding: itemPadding, listItemStyles: listItemStyles, children: [listStyle !== 'none' ? (_jsx(ListItemIcon, { children: _jsx(CheckIcon, { isSolid: listStyle === 'check-icon-solid' }) })) : null, _jsx(ListItemText, { listItemTextStyles: listItemTextStyles, children: listItem })] }, key))) }));
export default ItemsList;
